<template>
    <b-navbar v-if="$store.getters.isAuthenticated" toggleable="lg" class="main-menu navbar-vertical fixed-left" :class="'env-' + $store.state.cluster" :sticky="true">
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-badge v-if="isMobileLayout" class="badge-contrast text-white" :variant="environmentVariant">
            <span>Working on {{ $store.state.cluster }}</span>
        </b-badge>

        <b-collapse id="nav-collapse" class="w-100" is-nav>
            <b-nav class="w-100" fill pills justified vertical>
                <div class="text-uppercase f-500 w-100 py-4 f-13">Eye-In Hosting Panel</div>
                <b-nav-item-dropdown class="select-environment mb-4" no-caret right>
                    <template #button-content>
                        <div class="w-100 d-flex">
                            <span>Working on {{ $store.state.cluster }}</span>
                            <i class="fas fa-caret-down ml-auto mt-1"></i>
                        </div>
                    </template>
                    <b-dropdown-item v-for="cluster in clusters" :key="cluster.id" @click="setCluster(cluster)">
                        Switch to {{ cluster.id.toUpperCase() }}
                    </b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-text>{{ currentCluster.context_name }}</b-nav-text>
                <b-nav-item to="/">Projects</b-nav-item>
                <b-nav-item to="/projects-overview">Projects Overview</b-nav-item>
                <b-nav-item to="/state-of-services">State of services</b-nav-item>
                <b-nav-item to="/secrets">Secrets</b-nav-item>
                <b-nav-item to="/certificates">Certificates</b-nav-item>
                <b-nav-item to="/domains">Domains</b-nav-item>
                <hr class="w-100 mt-1">
                <b-nav-text>All environments</b-nav-text>
                <b-nav-item to="/https-redirects">HTTPS redirects</b-nav-item>
                <b-nav-item to="/firewalls">Firewalls</b-nav-item>

                <b-nav-item-dropdown class="user-account" right dropup no-caret>
                    <template #button-content>
                        <div class="w-100 d-flex">
                            <b-avatar size="26px" :src="$store.state.user.avatar_url"></b-avatar>
                            <span class="ml-2">{{ $store.state.user.username }}</span>
                            <i class="fas fa-caret-down ml-auto mt-1"></i>
                        </div>
                    </template>
                    <b-dropdown-item @click="logout"><i class="fas fa-sign-out"></i> Disconnect</b-dropdown-item>
                </b-nav-item-dropdown>
            </b-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>

import Network from "../../vue-components/helpers/Network.js";

export default {
    name: `app-menu`,
    data() {
        return {
        }
    },
    computed: {
        environmentVariant() {
            switch (this.$store.state.cluster) {
                case `dev`:
                    return `green`;
                case `demo`:
                    return `primary`;
                default:
                    return `danger`;
            }
        }
    },
    created() {
        this.getClusters();
    },
    methods: {
        getClusters() {
            Network.get(`/api/clusters`)
                .then(resp => {
                    this.$store.state.clusters = resp.data;
                })
        },
        logout() {
            this.$store.dispatch(`logout`, {forceLogout: true});
        },
        setCluster(cluster) {
            this.$store.state.cluster = cluster.id;
            localStorage.setItem(`cluster`, cluster.id);
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/style/bootstrap-theme";

.main-menu {
    padding-left: 1rem;
    padding-right: 1rem;
    overflow-y: auto;
    background: linear-gradient(to bottom, #f1f5f9, #f5ecec);
    border-right: 1px solid #e1e3e6;

    @media(min-width: 576px) {
        display: block;
        left: 0;
        top: 0;
        width: 100%;
        position: fixed;
        max-width: 250px;
        bottom: 0;
    }

    #nav-collapse {
        width: 100%;
    }

    .nav-item {
        margin-bottom: 10px;

        .nav-link {
            text-align: left;
            font-size: 1.1rem;
            color: #5a6474;

            &:hover {
                background: #e3e9ef;
            }

            &.router-link-exact-active {
                color: white;
                background: map-get($theme-colors, "primary");
            }
        }

        &.dropdown {
            &::v-deep .dropdown-menu {
                width: 100%;
            }

            &::v-deep .nav-link {
                border: 1px solid #e1e3e6;
                color: #5a6474;
                text-transform: uppercase;
                letter-spacing: 1px;
                font-weight: 500;
                background: white;
                text-align: left;
            }

            &.select-environment {

            }

            @media(min-width: 576px) {
                &.user-account {
                    position: absolute;
                    bottom: 1rem;
                    left: 1rem;
                    right: 1rem;
                }
            }
        }
    }


    &.env-dev {
        background: linear-gradient(to bottom, #f1f5f9, #edf8f2);

        .nav-item.dropdown.select-environment::v-deep .nav-link {
            background: #53bb8a;
            border: 1px solid #3d9a6e;
            color: white;
        }

        .nav-item .nav-link.router-link-exact-active {
            color: white;
            background: #53bb8a;
        }
    }

    &.env-demo {
        background: linear-gradient(to bottom, #f1f5f9, #edf6f8);

        .nav-item.dropdown.select-environment::v-deep .nav-link {
            background: map-get($theme-colors, "primary");
            border: 1px solid #284096;
            color: white;
        }

        .nav-item .nav-link.router-link-exact-active {
            color: white;
            background: map-get($theme-colors, "primary");
        }
    }

    &.env-live {
        background: linear-gradient(to bottom, #f1f5f9, #f5ecec);

        .nav-item.dropdown.select-environment::v-deep .nav-link {
            background: #d05151;
            border: 1px solid #a73939;
            color: white;
        }

        .nav-item .nav-link.router-link-exact-active {
            color: white;
            background: #d05151;
        }
    }
}
</style>
