<template>
    <div id="app">
        <app-menu></app-menu>
        <keep-alive>
            <router-view :key="$route.fullPath"/>
        </keep-alive>
    </div>
</template>

<script>
import appMenu from "@/layout/app-menu.vue";

export default {
    components: {appMenu},
    created() {
        const cluster = localStorage.getItem(`cluster`);
        if (!cluster) {
            localStorage.setItem(`cluster`, `dev`);
        }

        let token = new URLSearchParams(window.location.search).get(`token`);
        if (token) {
            window.history.pushState({}, document.title, `/`);
        }
    }
}
</script>

<style lang="scss">
@import "assets/style/global.scss";


  #app {
    //font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

.max-text-300 {
    word-wrap: break-word;
    max-width: 300px
}
.max-text-400 {
    word-wrap: break-word;
    max-width: 400px
}

.nav-link {
    user-select: none;
}

.pointer {
    cursor: pointer;
}
</style>
