import Vue from 'vue';
import Vuex from 'vuex';
import Network from "../vue-components/helpers/Network.js";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: null,
        cluster: localStorage.getItem(`cluster`) || `dev`,
        clusters: []
    },
    getters: {
        currentUser(state) {
            if (state.user) {
                return state.user;
            } else {
                return null;
            }
        },
        isAuthenticated(state) {
            return !!state.user;
        },
        cluster(state) {
            return state.cluster;
        },
        clusterObject(state) {
            return state.clusters.find(c => c.id === state.cluster);
        }
    },
    mutations: {
        startLogin(state) {
            state.loginStatus = `loading`
        },
        authSuccess(state, user) {
            state.loginStatus = `success`;
            state.user = user
        },
        notConnected(state) {
            state.loginStatus = `not_connected`;
        },
        authError(state) {
            state.loginStatus = `error`
        },
        loggedOut(state) {
            state.loginStatus = `logged_out`;
            state.user = null
        }
    },
    actions: {
        async getCurrentUser({commit, dispatch}) {
            commit(`startLogin`);
            let token = null;

            const currentURL = new URL(document.URL);
            const currentURLParams = new URLSearchParams(currentURL.search);

            if (currentURLParams.has(`token`)) {
                token = currentURLParams.get(`token`);
            }

            if (!token) {
                token = localStorage.getItem(`token`);
            }

            if (!token) {
                commit(`notConnected`);
                return false;
            }

            await Network.setToken(token);
            try {
                const response = await Network.get(`/api/auth/current-user`)
                commit(`authSuccess`, response.data);
            } catch (e) {
                console.error(e);
                dispatch(`logout`, {forceLogout: true});
            }
        },
        logout({commit}, payload = {}) {
            commit(`loggedOut`);
            localStorage.removeItem(`token`);
            Network.removeToken();

            const redirectURL = new URL(window.env.VUE_APP_URLS__LOGIN);
            redirectURL.searchParams.append(`redirect_url`, window.location.href);
            if (payload.forceLogout) {
                redirectURL.searchParams.append(`logout`, `1`);
            }

            window.location.href = redirectURL.toString();
        }
    },
    modules: {}
});
