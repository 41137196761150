import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store';

import Network from "../vue-components/helpers/Network.js";

Vue.use(VueRouter);

function ifAuthenticated(to, from, next) {
    if (store.getters.isAuthenticated) {
        next();
    } else {
        let token = new URLSearchParams(window.location.search).get(`token`);

        if (token) {
            Network.setToken(token);
        }

        store.dispatch(`getCurrentUser`)
            .then(() => {
                if (store.getters.isAuthenticated) {
                    next();
                }
            });
    }
}

const routes = [
    {
        path: `/certificates`,
        name: `certificates`,
        component: () => import(`@/views/certificates.vue`),
        beforeEnter: ifAuthenticated
    },
    {
        path: `/`,
        name: `projects`,
        component: () => import(`@/views/projects.vue`),
        alias: `/projects`,
        beforeEnter: ifAuthenticated
    },
    {
        path: `/project/:name`,
        name: `project`,
        component: () => import(`@/views/project.vue`),
        beforeEnter: ifAuthenticated
    },
    {
        path: `/projects-overview`,
        name: `projects-overview`,
        component: () => import(`@/views/projects-overview.vue`),
        beforeEnter: ifAuthenticated
    },
    {
        path: `/pods/:name/:tab?`,
        name: `pods`,
        component: () => import(`@/views/pods.vue`),
        beforeEnter: ifAuthenticated
    },
    {
        path: `/state-of-services`,
        name: `state-of-services`,
        component: () => import(`@/views/state-of-services.vue`),
        beforeEnter: ifAuthenticated
    },
    {
        path: `/secrets`,
        name: `secrets`,
        component: () => import(`@/views/secrets.vue`),
        beforeEnter: ifAuthenticated
    },
    {
        path: `/domains`,
        name: `domains`,
        component: () => import(`@/views/domains.vue`),
        beforeEnter: ifAuthenticated
    },
    {
        path: `/firewalls`,
        name: `firewalls`,
        component: () => import(`@/views/firewalls.vue`),
        beforeEnter: ifAuthenticated
    },
    {
        path: `/https-redirects`,
        name: `https-redirects`,
        component: () => import(`@/views/https-redirects.vue`),
        beforeEnter: ifAuthenticated
    }
];

const router = new VueRouter({
    mode: `history`,
    routes: routes
});

export default router;
