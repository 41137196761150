import Vue from "vue";
import {getLanguage, translate} from "./i18n.js";
import {getMarketIcon} from "./utils.js";
import env from "../../src/env.mjs"
import swal from "sweetalert";

function isCurrentURILocal() {
    return window.location.protocol !== `capacitor:` &&
        (window.location.host.indexOf(`localhost:`) === 0 ||
        window.location.hostname.indexOf(`127.0.0.1`) === 0 ||
        window.location.hostname.indexOf(`192.168.`) === 0 ||
        window.location.hostname.indexOf(`10.0.`) === 0);
}

if (window.env) {
    window.env = {...env, ...process.env, ...window.env};
} else {
    window.env = {...env, ...process.env};
}

Vue.mixin({
    data() {
        return {
            console: console,
            document: document,
            window: window
        }
    },
    computed: {
        isMobileLayout() {
            return this.$store.state.isMobileLayout;
        },
        isSmallLayout() {
            return this.$store.state.isSmallLayout;
        },
        isTabletLayout() {
            return this.$store.state.isTabletLayout;
        },
        isMobileOrTabletLayout() {
            return this.$store.state.isMobileOrTabletLayout;
        },
        isLargeLayout() {
            return this.$store.state.isLargeLayout;
        },
        isExtraLargeLayout() {
            return this.$store.state.isExtraLargeLayout;
        },
        language() {
            return getLanguage();
        },
        printMode() {
            return this.$store.state.printMode;
        },
        appApiUrl() {
            if (window.location.hostname.indexOf(`192.168.`) >= 0) {
                const apiPort = /:(\d{2,4})/g.exec(window.env.VUE_APP_API_URL)[1];
                return `${window.location.protocol}//${window.location.hostname}:${apiPort}`;
            } else {
                return window.env.VUE_APP_API_URL;
            }
        }
    },
    activated() {
        if (`serviceWorker` in navigator) {
            navigator.serviceWorker.getRegistration().then(registration => {
                if (registration) {
                    registration.update();
                }
            });
        }
    },
    created() {
        // this.onWindowResize();
        // // No choice but to update manually here, the window properties are not reactive
        // window.addEventListener(`resize`, this.onWindowResize);
    },
    methods: {
        getLayoutWidth() {
            return window.getLayoutWidth();
        },
        onWindowResize() {
            this.isMobileLayout = window.isMobileLayout;
            this.isSmallLayout = window.isSmallLayout;
            this.isTabletLayout = window.isTabletLayout;
            this.isMobileOrTabletLayout = window.isMobileOrTabletLayout;
            this.isLargeLayout = window.isLargeLayout;
            this.isExtraLargeLayout = window.isExtraLargeLayout;
        },
        showInvalidFields() {
            swal({
                title: this.tr(`Invalid fields|Champs non valides`),
                text: this.tr(`Some fields are missing or invalid|Certains champs sont manquant ou non valides`),
                icon: `info`
            });
        },
        showLoading(title, text, percent = null, showPercentage = true) {
            let content = null;
            let icon = `https://eyeinlivestorage.blob.core.windows.net/public/icons/loading.gif`;
            if (percent) {
                icon = null;
                content = document.createElement(`div`);
                content.className = `loading-container`;

                if (showPercentage) {
                    let percentageDiv = document.createElement(`div`);
                    percentageDiv.className = `percentage-value`
                    percentageDiv.innerHTML = `${Math.round(percent)}%`;
                    content.appendChild(percentageDiv);
                }

                let progress = document.createElement(`div`);
                progress.className = `progress sm`;
                let progressBar = document.createElement(`div`);
                progressBar.className = `progress-bar`;
                progressBar.style.width = percent + `%`;
                progress.appendChild(progressBar);
                content.appendChild(progress);

                let loadingIcon = document.createElement(`i`);
                loadingIcon.className = `float-right fas fa-spinner-third fa-spin`;
                loadingIcon.style.animationIterationCount = `1`;
                loadingIcon.style.animationDuration = `2s`;
                loadingIcon.style.animationTimingFunction = `ease-in-out`;
                content.appendChild(loadingIcon);
            }
            swal({
                title: title ? this.tr(title) : this.tr(`In progress|En cours`),
                text: text ? this.tr(text) : this.tr(`Please wait...|Merci de patienter...`),
                content: content,
                icon: icon,
                closeOnEsc: false,
                closeOnClickOutside: false,
                buttons: false
            });
        },
        showErrorMessage(title, text) {
            swal({
                title: title ? this.tr(title) : this.tr(`Error|Erreur`),
                text: text ? this.tr(text) : this.tr(`Something wrong happened...|Quelque chose d'inattendu s'est produit...`),
                icon: `error`
            });
        },
        showRenamePrompt(title, message, defaultValue, confirmText, icon, callback) {
            swal({
                title: title ? this.tr(title) : this.tr(`Rename|Renommer`),
                text: message ? this.tr(message) : this.tr(`Enter the new name for '${defaultValue}'|Entrez le nouveau nom pour '${defaultValue}'`),
                icon: icon || `info`,
                content: {
                    element: `input`,
                    attributes: {
                        defaultValue: defaultValue
                    }
                },
                buttons: {
                    cancel: true,
                    confirm: {
                        text: confirmText ? this.tr(confirmText) : this.tr(`Rename|Renommer`),
                        closeModal: false
                    }
                }
            }).then(name => {
                if (!name || name === defaultValue) {
                    swal.close();
                    return;
                }
                if (callback) {
                    callback(name);
                    swal.close();
                }
            });
        },
        hideLoading() {
            swal.close();
        },
        showSavedMessage(title, text, callback) {
            swal({
                title: title ? this.tr(title) : this.tr(`Saved|Enregistré`),
                text: text ? this.tr(text) : null,
                icon: `success`
            }).then(() => {
                if (typeof callback === `function`) {
                    callback();
                }
            });
        },
        showCreatedMessage(itemName = `The item|L'élément`, callback) {
            swal({
                title: this.tr(`Created|Ajouté`),
                text: this.tr(`The ${itemName} has been created successfully|${itemName} a été créé avec succès`),
                icon: `success`
            }).then(() => {
                if (typeof callback === `function`) {
                    callback();
                }
            });
        },
        showConfirmationMessage(confirmTitle = `Confirm|Confirmer`, confirmText = `Are you sure?|Êtes-vous sûr ?`, buttonText = `Confirm|Confirmer`, buttonClass = ``, icon = `warning`) {
            return swal({
                title: this.tr(confirmTitle),
                text: this.tr(confirmText),
                icon: icon || `warning`,
                buttons: {
                    cancel: this.tr(`Cancel|Annuler`),
                    confirm: {
                        text: this.tr(buttonText),
                        className: buttonClass
                    }
                }
            })
        },
        showToast(title, duration = 4000, position = `b-toaster-top-right`) {
            this.$bvToast.toast(` `, {
                title: this.tr(title),
                variant: `simple`,
                autoHideDelay: duration,
                toaster: position,
                noCloseButton: false
            });
        },
        showSavedToast() {
            this.showToast(`Saved|Enregistré`);
        },
        tr(values, language) {
            return translate(values, language);
        },
        hasPermission(permission) {
            return localStorage.getItem(permission) && localStorage.getItem(permission).trim() === `yes`;
        },
        removeTz(value) {
            if (!value)
                return value;
            return value.replace(/\.\d{3}Z/g, ``);
        },
        getLanguage: getLanguage,
        getEnv() {
            return window.env.VUE_APP_ENV;
        },
        isCurrentURILocal() {
            return isCurrentURILocal();
        },
        isLocal() {
            return this.getEnv() === `local`;
        },
        isDev() {
            return this.getEnv() === `dev`;
        },
        isLive() {
            return (this.getEnv() !== `local` && this.getEnv() !== `dev` && this.getEnv() !== `demo`);
        },
        isDemo() {
            return this.getEnv() === `demo`;
        },
        isNext() {
            return this.getEnv() === `next`;
        },
        getPublicIcon(name, extension = `png`) {
            return `https://eyeinlivestorage.blob.core.windows.net/public/icons/ui/${name}.${extension}`;
        },
        getPublicImage(path) {
            return `https://eyeinlivestorage.blob.core.windows.net/public/eye-intelligence` + path;
        },
        getStorageUrl(path) {
            if (this.isLive() || this.isNext()) {
                return `https://eyeinlivestorage.blob.core.windows.net` + (path || ``);
            } else {
                return `https://eyeindevstorage.blob.core.windows.net` + (path || ``);
            }
        },
        getMarketIcon: getMarketIcon
    }
});
