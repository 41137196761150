<template>
    <div class="app-page">
        <slot name="page-header"></slot>
        <component :is="noContainer ? 'div' : 'b-container'">
            <slot></slot>
        </component>
    </div>
</template>

<script>

export default {
    name: `app-page`,
    props: {
        noContainer: {type: Boolean}
    }
}
</script>

<style lang="scss" scoped>
.app-page {
    @media(min-width: 576px) {
        padding-left: 250px;
    }
}
</style>
